
.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url('./assets/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 80%;
  transform: scale(0.8);
  animation: scale 3s infinite ease-in-out;
  z-index: 0;
}

.app__wrapper_img_other {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper_img_other img {
  width: 100%;
  /* height: 600px; */
  z-index: 0;
  border-radius: 20px;
  overflow: hidden;
}



@keyframes scale {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(0.9) ;
  }
  100% {
    transform: scale(0.8) ;
  }
}


.muzieknootjes {
  color: white;
  display: block;
  margin: auto;
  position: absolute;
  width: 50%;
  min-width: 300px;
  height: 200px;
  border: 0px solid #000;
  z-index: 1;
}

.noot-1,
.noot-2,
.noot-3,
.noot-4 {
  position: absolute;
  animation: notes 2s infinite linear;
  font-size: 35px;
  opacity: 0;
}

.noot-1 {
  top: 60px;
  left: 0;
  animation-delay: 0.5s;
}

.noot-2 {
  top: 30px;
  left: 30%;
  animation-delay: 1s;
}

.noot-3 {
  top: 90px;
  left: 60%;
  animation-delay: 1.5s;
}

.noot-4 {
  top: 40px;
  left: 90%;
  animation-delay: 2s;
}

.custom__button {
  background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-base);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 23px;
}

.p__opensans {
  font-family: var(--font-alt);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.spoon__img {
  width: 45px;
}

@media screen and (min-width: 2000px) {
  .custom__button,
  .p__cormorant {
    font-size: 37px;
    line-height: 67px;
  }

  .p__opensans {
    font-size: 30px;
    line-height: 50px;
  }

  .headtext__cormorant {
    font-size: 150px;
    line-height: 210px;
  }

  .spoon__img {
    width: 80px;
  }
}

@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .app__wrapper {
    overflow: hidden;
  }
  .section__padding {
    padding: 4rem;
  }
  .app__wrapper_img_other img {
    width: 70%;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {

  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
  .app__wrapper_img_other img {
    width: 60%;
  }
}

@keyframes notes {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1.5) translate(50%, -50%);
  }
  80% {
    opacity: 0;
    transform: scale(1.5) translate(100%, -100%);
  }
  100% {
    transform: scale(1.5) translate(100%, -100%);
    opacity: 0;
  }
}


.holder {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 50%;
  height: 50%;
  /* Y axis overflowed  */
}

.particles {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.particle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50%;
  height: 100%;
  transform: translate(-1000%, -1000%);
  transform-origin: 100% 50%;
}

.particle div {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100%;
  transform-origin: 100% 50%;
  animation: particle-1 10s forwards;
}

@keyframes particle-1 {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(50deg);
  }
}

.particle div:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  width: 30px;
  height: 30px;
  transform: translate(-5000%, -5000%);
  background-color: white;
  border-radius: 50%;
  animation: particle-2 10s forwards;
}

@keyframes particle-2 {
  0% {
    left: 100%;
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: 0%;
  }
}

.circle{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  height: 100%;
  background-color: linear-gradient(to right, #800080, #90EE90);
  color: white;
  text-align: center;
  line-height: 100px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  border-radius: 50%;
  font-size: 1.3rem;
  &::after,&::before{
      content: "";
      display: block;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, #800080, #90EE90);
      border-radius: 50%;
      animation: grow 4s ease-in-out infinite;
  }
  &::after{
      background: rgba(255, 95, 122,0.4);
      &::before{
      content: "";
      display: block;
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      background: rgb(95, 132, 255);
      border-radius: 50%;
      animation: grow 4s ease-in-out infinite;
      }
  }
  &::before{
      background: linear-gradient(to right, #800080, #90EE90);;
      animation-delay: -0.5s;
  }
}
@keyframes grow{
  0%{
      transform: scale(0.5);
      opacity: 1;
  }
  100%{
      transform: scale(1.5);
      opacity: 0;
  }
  /* 100%{
    transform: scale(0.5);
    opacity: 1;
} */
}

